.shk {
  --color-primary: #0d6efd;
  --color-opac: #b9b9b94d;
  --color-theme: #27272a;
  --color-secondary: #767676;
  --color-text: #333;
  --color-shadow: #b9b6b680;
  --color-handle-shadow-mobile: #b9b6b6;

  --background-body: var(--color-theme);
  --color-title: var(--color-text);
  --color-artist: var(--color-secondary);
  --color-button: var(--color-primary);
  --color-button-disabled: var(--color-opac);
  --color-button-active-background: var(--color-opac);
  --color-handle: var(--color-primary);
  --color-handle-disabled: #b9b9b9;
  --color-bar-loaded: var(--color-opac);
  --color-bar-played: var(--color-primary);
  --color-time: var(--color-secondary);
  --color-spinner: var(--color-primary);
  --color-live-symbol: var(--color-primary);
  --color-live-text: var(--color-primary);
  --shadow-body: 2px 2px 8px -2px var(--color-shadow);
  --shadow-body-mobile: 0 -2px 6px -2px var(--color-shadow);
  --shadow-handle: 0px 2px 10px 2px var(--color-shadow);
  --shadow-handle-mobile: 2px 2px 8px -2px var(--color-handle-shadow-mobile);
}
.shk[data-theme="dark"] {
  --color-theme: #18181b;
  --color-secondary: #fff;
  --color-text: #f8f9fa;
  --color-handle-shadow-mobile: #141414;
  --color-shadow: #14141480;
}
@media (prefers-color-scheme: dark) {
  .shk[data-theme="auto"] {
    --color-theme: #333;
    --color-secondary: #b9b6b6;
    --color-text: #fff;
    --color-handle-shadow-mobile: #141414;
    --color-shadow: #14141480;
  }
}
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
@keyframes marquee {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translateX(-100%);
  }
}
.shk {
  background-color: transparent !important;
  font-weight: 400;
  font-size: 16px;
  font-family: inherit;
  line-height: 1.42;
  cursor: auto;
  position: relative;
  border: none;
  color: var(--color-text);
}
.js-focus-visible:focus:not(.focus-visible),
.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}
.shk[data-fixed-type='fixed'] {
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 0;
  width: 100%;
}
.shk[data-fixed-type="fixed"][data-fixed-pos="top"] {
  top: 0;
  bottom: auto;
}
.shk[data-seeking] .shk-player {
  cursor: grabbing;
}
.shk button { 
  font-family: inherit;
}
.shk-player {
  height: 90px;
  width: full;
  box-sizing: border-box;
  border-radius: 8px;
  background: var(--background-body);
  position: relative;
  z-index: 10;
}
.shk-body {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  user-select: none;
  position: relative;
}
.shk-info {
}
.shk-primary {
  display: flex;
  align-items: center;
}
.shk-template {
  height: 92px;
}
@media (max-width: 900px) {
  .shk-template {
    height: 116px;
  }
  .shk-inner-template {
    padding-bottom: 20px;
  }
}
.shk-timing {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50vw;
}
@media (max-width: 1200px) {
  .shk-timing {
    width: 40vw;
  }
}
@media (max-width: 1000px) {
  .shk-timing {
    width: 30vw;
  }
}

@media (max-width: 900px) {
  .shk-timing {
    position: absolute;
    bottom: 10px;
    left: -10px;
    width: 98vw;
  }
}

.shk-title_wrap {
  max-width: 240px;
}
@media (max-width: 1000px) {
  .shk-title_wrap {
    max-width: 200px;
  }
}
@media (max-width: 540px) {
  .shk-info {
    scale: 0.8;
    margin-left: -25px;
    margin-right: -40px;
  }
  .shk-title_wrap {
    max-width: 150px;
  }
}
@media (max-width: 460px) {
  .shk-info {
    scale: 0.62;
    margin-left: -50px;
    margin-right: -70px;
  }
}
@media (max-width: 400px) {
  .shk-btn_speed {
    display: none;
  }
}
.shk-cover {
  width: 60px;
  height: 60px;
  background-repeat: no-repeat;
  background-size: cover;
}
.shk-main {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.shk-text {
  overflow: hidden;
}
.shk-artist,
.shk-title {
  white-space: nowrap;
  margin: auto;
}
.shk-artist {
  text-overflow: clip;
  font-weight: 500;
  color: var(--color-artist);
}
.shk-title_wrap[data-overflow] {
  mask-image: linear-gradient(to right,transparent,#202124 5%,#202124 95%,transparent);
}
.shk-title_inner {
  display: inline-flex;
  overflow: visible;
}
.shk-title_wrap[data-overflow] .shk-title_inner {
  padding: 0 3%;
  box-sizing: border-box;
  white-space: nowrap;
  animation-duration: inherit;
}
.shk-title_wrap[data-overflow] .shk-title_inner::after {
  display: inline-block;
  content: attr(data-title);
}
.shk-title_wrap[data-overflow] .shk-title_inner::after,
.shk-title {
  font-weight: 300;
  font-size: 1.33em;
  color: var(--color-title);
}
.shk-title_wrap[data-overflow] .shk-title_inner::after,
.shk-title_wrap[data-overflow] .shk-title {
  animation: marquee linear infinite;
  animation-duration: inherit;
  padding-right: 60px;
}
.shk-btn {
  appearance: none;
  background: transparent;
  border: none;
  cursor: pointer;
  color: var(--color-button);
  fill: currentColor;
  box-sizing: content-box;
  padding: 10px;
  position: relative;
  font-size: 1em;
  line-height: normal;
}
.shk-btn:disabled {
  color: var(--color-button-disabled);
  cursor: not-allowed;
}
.shk-btn::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--color-button-active-background);
  z-index: -1;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s cubic-bezier(0,0,0.2,1), visibility linear 1s;
}
.shk-btn:not(:disabled):active::after {
  opacity: 1;
  visibility: visible;
}
a.shk-btn:visited {
  color: var(--color-button);
}
.shk-btn svg {
  width: 24px;
  height: 24px;
  vertical-align: middle;
}
.shk-controls {
  position: relative;
  margin: 0 auto;
  flex: 0 0 auto;
}
.shk-controls .shk-btn {
  border-radius: 100%;
}
.shk-controls > div {
  flex-flow: row nowrap;
  align-items: center;
  display: flex;
}
.shk-controls_basic {
}
.shk-controls_extra {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  margin-right: 44px;
  padding-right: 0 8px;
  white-space: nowrap;
  overflow: hidden;
  z-index: 10;
  justify-content: flex-end;
  background: var(--background-body);
  transition: width .2s ease-in-out, opacity .1s .1s linear, visibility .2s;
}
.shk[data-extra] .shk-controls_extra {
  opacity: 1;
  visibility: visible;
  width: calc(100% - 44px);
  transition: width .2s ease-in-out, opacity .1s linear;
}
.shk[data-extra] .shk-controls_extra .shk-btn {
  display: inline-block;
}
.shk-btn_toggle svg {
  width: 32px;
  height: 32px;
}
.shk[data-play="paused"] .shk-btn_play,
.shk[data-play="playing"] .shk-btn_pause {
  display: inline;
}
.shk[data-play="paused"] .shk-btn_pause,
.shk[data-play="playing"] .shk-btn_play {
  display: none;
}
.shk-btn_speed {
  font-size: 0.86em;
  font-weight: 400;
  width: 44px;
  height: 44px;
  padding: 0;
}
.shk-btn_more {
  position: relative;
  z-index: 11;
  transform: none;
  transition: transform .2s .1s;
}
.shk[data-extra] .shk-btn_more {
  transform: rotate(90deg);
}
svg.shk-btn_unmute,
.shk[data-mute] svg.shk-btn_mute {
  display: inline-block;
}
svg.shk-btn_mute,
.shk[data-mute] svg.shk-btn_unmute {
  display: none;
}
@media (max-width: 641px) {
  .shk-btn_fullscreen {
    display: none;
  }
  .shk-btn_queue {
    display: none;
  }
}
svg.shk-btn_enterfullscreen,
.shk[data-fullscreen] svg.shk-btn_exitfullscreen {
  display: inline-block;
}
svg.shk-btn_exitfullscreen,
.shk[data-fullscreen] svg.shk-btn_enterfullscreen {
  display: none;
}
.shk-bar_wrap {
  width: 100%;
  cursor: pointer;
  position: relative;
  z-index: 1;
}
.shk-bar {
  
  position: relative;
  border-radius: 9999px;
  height: 10px;
  width: 100%;
  box-sizing: border-box;
  background: black;
}
.shk-bar_loaded,
.shk-bar_played {
  border-radius: 9999px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  transition: width ease-in .1s;
}
.shk-bar_loaded {
  background: var(--color-bar-loaded);
}
.shk-bar_played {
  background: var(--color-bar-played);
}
.shk[data-seeking] .shk-bar_played {
  transition: none;
}
.shk-bar-handle {
  border-radius: 9999px;
  background: var(--color-handle);
  position: absolute;
  right: -10px;
  width: 20px;
  height: 20px;
  padding: 0;
  border: 0;
  box-sizing: content-box;
  cursor: grab;
  border-radius: 20px;
}
.shk-bar-handle:disabled {
  cursor: not-allowed;
  box-shadow: none;
  background-color: var(--color-handle-disabled);
}
.shk-bar-handle:active {
  cursor: grabbing;
}
.shk-display {
  position: relative;
}
.shk-loader {
  display: none;
  vertical-align: middle;
  color: var(--color-spinner);
}
.shk[data-loading] .shk-loader {
  display: inline-block;
}
.shk-loader svg {
  width: 12px;
  height: 12px;
  animation: rotate 1.4s linear infinite;
  position: relative;
}
.shk-visuallyhidden:not(:focus):not(:active) {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
.shk-time {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.8em;
  font-weight: 300;
  color: var(--color-time);
}
.shk .shk-live {
  display: none;
  align-items: center;
  text-transform: uppercase;
  font-size: 0.8em;
  font-family: sans-serif;
  color: var(--color-live-text);
}
.shk .shk-live::before {
  content: '';
  background: var(--color-live-symbol);
  border-radius: 50%;
  width: 0.5em;
  height: 0.5em;
  margin-right: 2px;
}
.shk-icons {
  display: none;
}
.shk[data-live] .shk-bar,
.shk[data-live] .shk-time {
  display: none;
}
.shk[data-live] .shk-live {
  display: inline-flex;
}
@media (min-width: 641px) {
  .shk-player {
    margin: 12px 0px 0px 0px;
    padding: 20px 16px;
    box-shadow: var(--shadow-body);
  }
  .shk-main {
    align-items: flex-end;
    padding: 10px 0;
    max-width: calc(100% - 96px);
    flex-direction: row;
  }
  .shk-text {
    flex: 2 1 auto;
  }
  .shk-bar_wrap {
    left: 0px;
    right: 0px;
  }
  .shk-bar {
    transition: height .15s ease-in;
  }
  .shk-bar_wrap:hover .shk-bar {
  }
  .shk-bar-handle {
    top: -9.5px;
    border: 4px solid transparent;
    box-shadow: var(--shadow-handle);
    transform: scale(.0001);
    transition: all .1s ease-in;
  }
  .shk-bar-handle.focus-visible,
  .shk-bar_wrap:hover .shk-bar-handle:not(:disabled) {
    border-color: #fff;
    transform: scale(0.8);
  }
  .shk-display {
    top: -2px;
    right: 8px;
  }
  .shk-loader {
    margin-right: 4px;
  }
}
.shk-time_duration::before {
  content: '/';
  margin: 0 4px;
}